import {ComponentLoader} from "ui-base/src/AbstractComponent";
import {AjaxFormComponent} from "ui-base/src/Components/Form/_resources/ts/AjaxFormComponent";

// For jquery type hinting
declare let $: any;

export abstract class ForgottenPasswordFormC extends AjaxFormComponent {

    public static selector: string = 'forgotten-password-form-component';

    protected onResponseSuccess(data) {
        $('[forgotten-password-form]').hide();
        $('[forgotten-password-success-message]').show();
    }

    protected onResponseFail(error) {
        $('[forgotten-password-form]').hide();
        $('[forgotten-password-error-message]').show();
    }

}

new ComponentLoader(ForgottenPasswordFormC);
